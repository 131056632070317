@import 'styles/mixins/_mixins';

.content {
  display: flex;
  flex-direction: column;
  gap: @gap-regular;
  height: 100%;
  flex-grow: 1;
  overflow: auto;
}

.listContainer {
  height: 100%;
  flex-grow: 1;
  .is-scrollable();
}
