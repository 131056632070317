.menuItem {
  color: var(--color-white);
  background: var(--color-modal);
  padding: var(--base-unit-l) var(--base-unit-xl);
  white-space: nowrap;
  display: flex;

  &.menuItemLight {
    background-color: transparent;
    color: var(--color-black);
  }
}

.menuItem:hover {
  background: var(--color-neutral);

  &.menuItemLight {
    background-color: var(--color-neutral-lightest);
  }
}

.menuItem a:visited {
  color: inherit;
}

.menuItem > a, .menuItem > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: auto;

  margin: calc(-1 * var(--base-unit-l)) calc(-1 * var(--base-unit-xl));
  padding: var(--base-unit-l) var(--base-unit-xl);
  color: var(--color-white);
  text-decoration: none !important;
  text-align: left;
}

.menuItem.menuItemLight  > a, .menuItem.menuItemLight  > span {
  color: var(--color-black);
}

.menuItem > a:global(.active), .menuItem > span:global(.active) {
  background: var(--color-neutral-not-so-light);
}

.menuItem :global(.cw-icon) {
  font-size: 1.8rem;
  margin-top: -1.4rem;
  margin-bottom: -1.4rem;
  padding-right: 0;
}

.menuItem > span:first-child, .menuItem > a > span:first-child, .menuItem > a > :global(.cw-icon:first-child), .menuItem > :global(.cw-icon:first-child) {
  margin-right: var(--base-unit-l);
  flex: none;
}

.menuItemClickable {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menuItemDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.menuItemActive {
  color: var(--color-modal);
}

.menuItemActive:not(.menuItemLight) {
  background: var(--color-white);
}

.menuItemActive.menuItemLight {
  background-color: var(--color-neutral-lightest);
}
