@import "styles/mixins/_mixins.less";


.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 2.2rem;
  align-items: center;
  margin-bottom: 1.9rem;

  &.withFilter {
    margin-bottom: 0;
  }
}

.back {
  cursor: pointer;
  font-size: 2.4rem;
}

.title {
  font-size: 2.8rem;
  font-weight: 500;
}

.inputContainer {
  flex: 1;
  margin: 0 -@gap-xlarge;

  .filterInput {
    border-radius: 0;
    border-right: none;
    border-left: none;
    padding-left: 1.8rem;
  }
}
