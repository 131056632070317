@import "styles/mixins/_mixins.less";

.addToCollectionModal {
  height: 90%;
  max-width: 80rem;
  width: 80%;

  .media-breakpoint-down(@screen-sm, {
    width: 100%;
    height: 100%;
    min-height: auto;
  });

  :global {
    .modalContent {
      padding: 0;
    }

    .modalHeader {
      border-bottom: thin solid @color-neutral;
    }

    .modalTitle {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: @gap-small;
    }

    .modalChildren {
      height: 100%;
      display: flex;
      flex-direction: row;

      .media-breakpoint-down(@screen-sm, {
        flex-direction: column-reverse;
        justify-content: flex-end;
      });
    }
  }
}

.treeContainer {
  background-color: @color-grey-29;
  border-right: thin solid @color-neutral;
  flex: 1;
}


.formContainer {
  .is-scrollable();
  flex: 1;

  .previewContainer {
    display: flex;
    flex-direction: column;
    gap: @gap-small;
    margin-bottom: @gap-xlarge;
  }

  .rightContainer {
    padding: @gap-xlarge;
    display: flex;
    flex-direction: column;
    gap: @gap-xlarge;
  }
}

.media-breakpoint-down(@screen-sm, {
  .formContainer {
    flex: 1 0 auto;
  }
  .treeContainer {
    flex: 1 1 auto;
    // flex items won’t shrink below their content size, min-height: 0;
    // allows them to respect available space.
    min-height: 0;
  }
});

