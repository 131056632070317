@import "styles/mixins/_mixins.less";

.treeItemModal {
  :global {
    .modalContent {
      padding: 0;
    }

    .modalTitle {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: @gap-small;
    }
  }

  &.withTree {
    height: 90%;
    min-height: 81rem;

    :global {
      .modalChildren {
        height: 100%;
      }
    }
  }

  .treeContainer {
    width: 40rem;
    height: 100%;
    background-color: @color-grey-23;
    border-right: thin solid @border-color;

    .is-scrollable();
  }
}

.treeItemModalContent {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  gap: @gap-small;
  margin-bottom: @gap-xlarge;
}

.formContainer {
  width: 44rem;
  padding: @gap-xlarge;
  .is-scrollable();
}
