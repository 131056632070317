@import "./mixins/_mixins.less";
@import "./mixins/_variables.less";
@import "./mixins/_helpers";

@import "./modals.less";
@import "./libs-override.less";
@import "./animations.less";

:root {
  color-scheme: normal;

  --font-main: @font-main;

  --base-unit-xxl: calc(var(--base-unit-m) * 8);
  --base-unit-xl: calc(var(--base-unit-m) * 4);
  --base-unit-l: calc(var(--base-unit-m) * 2);
  --base-unit-m: @gap-small;
  --base-unit-s: calc(var(--base-unit-m) / 2);

  --gap-small: @gap-small;
  --gap-regular: @gap-regular;

  --safe-area-left: 0rem;
  --safe-area-top: 0rem;
  --safe-area-right: 0rem;
  --safe-area-bottom: 0rem;

  --safe-area-paddings: var(--safe-area-top) var(--safe-area-right) var(--safe-area-bottom) var(--safe-area-left);

  --unified-padding-right-xsmall: calc(var(--base-unit-m) + var(--safe-area-right));
  --unified-padding-left-xsmall: calc(var(--base-unit-m) + var(--safe-area-left));

  --unified-padding-right-small: calc(((var(--base-unit-m) + var(--base-unit-l)) / 2) + var(--safe-area-right));
  --unified-padding-left-small: calc(((var(--base-unit-m) + var(--base-unit-l)) / 2) + var(--safe-area-left));
  --unified-padding-top-small: calc((var(--base-unit-m) + var(--base-unit-l)) / 2);
  --unified-padding-bottom-small: calc((var(--base-unit-m) + var(--base-unit-l)) / 2);
  --unified-paddings-small: var(--unified-padding-top-small) var(--unified-padding-right-small) var(--unified-padding-bottom-small) var(--unified-padding-left-small);

  --unified-padding-right-large: calc((var(--base-unit-m) + var(--base-unit-l)) + var(--safe-area-right));
  --unified-padding-left-large: calc((var(--base-unit-m) + var(--base-unit-l)) + var(--safe-area-left));
  --unified-padding-top-large: var(--base-unit-m);
  --unified-padding-bottom-large: var(--base-unit-m);

  --unified-paddings-large: var(--unified-padding-top-large) var(--unified-padding-right-large) var(--unified-padding-bottom-large) var(--unified-padding-left-large);

  --border-radius-primary: @border-radius-primary;

  --color-warning: @color-warning;
  --color-accent: @color-accent;
  --color-accent-dark: @color-accent-dark;
  --color-accent-light: @color-accent-light;

  --color-primary: @color-primary;
  --color-primary-dark: @color-primary-dark;
  --color-primary-darkest: @color-primary-darkest;
  --color-secondary: @color-secondary;
  --color-secondary-dark: @color-bg-secondary-dark;
  --color-tertiary: @color-tertiary;
  --color-attention: @color-attention;
  --color-primary-light: @color-primary-light;

  --color-black: @color-black;
  --color-white: @color-white;
  --color-white-rgb: @color-white-rgb;
  --color-orange: @color-orange;
  --color-grey-0: @color-grey-0;
  --color-grey-1: @color-grey-1;
  --color-grey-4: @color-grey-4;
  --color-grey-5: @color-grey-5;
  --color-grey-6: @color-grey-6;
  --color-grey-7: @color-grey-7;
  --color-grey-8: @color-grey-8;
  --color-grey-9: @color-grey-9;
  --color-grey-10: @color-grey-10;
  --color-grey-11: @color-grey-11;
  --color-grey-12: @color-grey-12;
  --color-grey-13: @color-grey-13;
  --color-grey-14: @color-grey-14;
  --color-grey-15: @color-grey-15;
  --color-grey-16: @color-grey-16;
  --color-grey-17: @color-grey-17;
  --color-grey-18: @color-grey-18;
  --color-grey-19: @color-grey-19;
  --color-grey-20: @color-grey-20;
  --color-dark-blue-1: @color-dark-blue-1;
  --color-yellow-1: @color-yellow-1;
  --color-red-1: @color-red-1;
  --color-primary-links: @color-primary-links;

  --color-neutral: @color-neutral;
  --color-neutral-dark: @color-neutral-dark;
  --color-neutral-not-so-light: @color-neutral-not-so-light;
  --color-neutral-lightest: @color-neutral-lightest;
  --color-neutral-light: @color-neutral-light;

  --color-regular-links: @color-regular-links;
  --color-regular-links-visited: @color-regular-links-visited;

  --color-font-main-light: @color-font-main-light;

  --color-border: var(--color-neutral);
  --color-border-color: @border-color;
  --color-border-light: var(--color-neutral-lightest);
  --color-modal: @color-modal;

  --color-purple: @color-purple;
  --color-grey-300: @color-grey-300;

  --color-grade-ou: @color-grade-ou;
  --color-grade-ex: @color-grade-ex;
  --color-grade-vg: @color-grade-vg;
  --color-grade-gd: @color-grade-gd;
  --color-grade-fr: @color-grade-fr;
  --color-grade-pr: @color-grade-pr;

  // top and bottom toolbars
  --toolbar-height: 5.0rem;
  --header-height: @header-height;

  --media-xs: @media-xs;
  --media-s: @media-s;
  --media-m: @media-m;
  --media-l: @media-l;

  --screen-xxs: @screen-xxs;
  --screen-xs: @screen-xs;
  --screen-sm: @screen-sm;
  --screen-md: @screen-md;
  --screen-lg: @screen-lg;
  --screen-ml: @screen-ml;
  --screen-xlg: @screen-xlg;
  --screen-xxlg: @screen-xxlg;
  --screen-xxxlg: @screen-xxxlg;

  --border-radius-secondary: @border-radius-secondary;
}

@supports (padding: env(safe-area-inset)) {
  :root {
    --safe-area-left: env(safe-area-inset-left);
    --safe-area-top: env(safe-area-inset-top);
    --safe-area-right: env(safe-area-inset-right);
    --safe-area-bottom: env(safe-area-inset-bottom);
  }
}

* {
  box-sizing: border-box;
}

html {
  font-weight: 400;
  font-size: @base-font-size;
  font-family: @font-main;
  background: @color-black;

  .media-breakpoint-down(@screen-sm, {
    font-size: @base-font-size - 1px;
  });

  .media-breakpoint-up(@screen-xxlg, {
    font-size: @base-font-size + 0.5px;
  });

  .media-breakpoint-up(@screen-xxxlg, {
    font-size: @base-font-size + 1px;
  });
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-size: 1.6rem;

  //-webkit-font-smoothing: antialiased;
  color: @color-white;
  line-height: 1.2;

  .is-scrollable();

  @media @smartphone {
    margin-bottom: 0;
  }
}

h2, h3, h4, h5, h6, strong {
  font-weight: 500;
}

button {
  font-size: inherit;
  font-family: inherit;
}

#app {
  height: 100%;
}

// this CSS class comes from Cutwise Player
// iOS Safari makes possible to scroll page in fullscreen mode so we block this
:global(.cwp-has-fullscreen-element) {
  position: fixed;
  overflow: hidden;
}

:local {
  .has-custom-scroll {
    .has-custom-scroll()
  }
}

.cw-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
}
