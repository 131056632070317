@import 'styles/mixins/_mixins';

.forwardModal {
  --destination-list-horizontal-padding: 2.2rem;
  height: 90%;

  .media-breakpoint-down(@screen-sm, {
    height: 100%;
  });

  :global {
    .modalHeader {
      .media-breakpoint-up(@screen-sm, {
        border-bottom: thin solid @color-neutral;
      })
    }

    .modalContent {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .modalTitle {
      width: 100%;
      font-weight: inherit;
      font-size: inherit;
    }

    .modalChildren {
      height: 100%;

      .media-breakpoint-down(@screen-sm, {
        display: flex;
        flex-direction: column;
      });
    }
  }
}

.content {
  display: flex;
  gap: @gap-regular;
  height: 100%;
}

.baseIcon {
  color: @color-grey-21;
}
