@import "_variables";

.clearfix() {
  zoom:1;
  &:before, &:after{ content:""; display:table; }
  &:after{ clear: both; }
}

.xxs-container() {
  width: @media-xxs !important;
  height: @media-xxs !important;
}
.xs-container() {
  width: @media-xs !important;
  height: @media-xs !important;
}
.s-container() {
  width: @media-s !important;
  height: @media-s !important;
}
.m-container() {
  width: @media-m !important;
  height: @media-m !important;
}
.l-container() {
  width: @media-l !important;
  height: @media-l !important;
}
.xl-container() {
  width: @media-xl !important;
  height: @media-xl !important;
}

.media-breakpoint-down(@size, @rules) {
  @media screen and (max-width: (@size - 0.1rem)) { @rules(); }
}
.media-breakpoint-up(@size, @rules) {
  @media screen and (min-width: @size) { @rules(); }
}
.media-breakpoint-only(@size-min, @size-max, @rules) {
  @media screen and (min-width: @size-min) and (max-width: (@size-max - 0.1rem)) { @rules(); }
}


.is-scrollable(@track-color: @color-black, @thumb-color: @color-neutral, @thumb-width: 1rem) {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  .has-custom-scroll(@track-color, @thumb-color, @thumb-width);
}

.has-custom-scroll(@track-color: @color-black, @thumb-color: @color-neutral, @thumb-width: 1rem) {
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: @track-color;
  }

  &::-webkit-scrollbar {
    width: @thumb-width;
    padding: 10px;
    background-color: @track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @thumb-color;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.has-huge-left-padding() {
  padding: @gap-regular @gap-regular @gap-regular 6 * @gap-regular;

  .media-breakpoint-down(@screen-md, {
    padding: var(--unified-paddings-large);
  });
}

.has-label-appearance() {
  font-size: 1.6rem;
  color: @color-neutral-lightest;
}

.has-value-appearance() {
  font-size: 1.6rem;
  font-weight: 500;
  color: @color-white;
}

.has-centered-content() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.has-three-dots-on-overflow() {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.has-disabled-appearance() {
  filter: brightness(50%);
  pointer-events: none;
}

.reset-button {
  border: none;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
  font-family: inherit;
  outline: none;
  -webkit-appearance: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
}

.base-link {
  cursor: pointer;
  color: var(--color-regular-links);
  text-decoration: none;
}

.single-page-responsive-font-size {
  // all values below were calculated manually

  .media-breakpoint-down(60rem, {
    font-size: @base-font-size;
  });

  @step: 5;
  @breakpoints: range(60, 120, @step);
  @initialViewWidth: 1.05;
  @delta: .04;

  each(@breakpoints, {
    @start: unit(@value, rem);
    @end: unit(@value + @step, rem);

    .media-breakpoint-only(@start, @end, {
      font-size: unit(@initialViewWidth - @delta * (@index - 1), vw);
    });
  });

  .media-breakpoint-up(130rem, {
    font-size: .475vw
  });
}

.product-block-grid {
  padding: @gap-regular @gap-small 0 @gap-small;
  gap: 0 @product-grid-gap;
  display: grid;
  justify-content: space-around;
  align-items: start;
  grid-auto-flow: column;
}


.product-block-subgrid {
  grid-template-rows: subgrid;
  display: grid;
  grid-row: span 5;
}
