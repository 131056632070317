@import "styles/mixins/_mixins.less";

.pageErrorWrapper {
  margin: @gap-regular * 2 0 0 0;
}

.pageError {
  font-size: 1.8rem;
  text-align: center;
  max-width: @screen-xs;
  margin: 0 auto;
  color: @color-white;
  padding: 0 var(--unified-padding-right-large) 0 var(--unified-padding-left-large);
}

.pageErrorTitle {
}

.pageErrorCode {
  color: @color-primary-dark;
  font-size: 16.0rem;
  line-height: 1;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  .media-breakpoint-down(@screen-xs, {
    font-size: 12.0rem;
  });
}

.pageErrorImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: @gap-regular * 2 0 0 0;
}

.pageErrorImage img {
  display: block;
  .media-breakpoint-down(@screen-xs, {
    width: 60%;
  });
}

.pageErrorDescription {
  line-height: 1.3;
  text-align: left;
  margin: @gap-regular * 2 0 0 0;
}

.pageErrorDescriptionHasLinks {
  width: fit-content;
  margin: 0 auto @gap-regular * 2 auto;
}

.pageErrorDescriptionLinks {
  margin: @gap-regular * 2 0 0 0;
}

.withMarginBottom {
  vertical-align: -0.2rem;
}

.center {
  width: fit-content;
  margin: auto;
}
