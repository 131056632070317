@import "styles/mixins/_mixins.less";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list {
  flex-grow: 1;
  overflow: hidden;
  .is-scrollable();
}

.inputContainer {
  flex-shrink: 0;
  margin-top: @gap-xlarge;
  padding: 0 1.5rem;
  margin-bottom: 1rem;
}

.icon {
  font-size: 2.4rem;
  line-height: 1;

  &.folder {
    color: @color-gold-2;
  }
}
