@import "styles/mixins/_mixins";

.animatedDropdown {
  --dropdown-height: 3.6rem;
  position: relative;
  z-index: 5;
  min-width: 20.0rem;

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 var(--base-unit-l) 0 0.5rem;
    z-index: 1;
    position: relative;
    border-radius: calc(var(--dropdown-height) / 2);
    background: var(--color-modal);
    height: var(--dropdown-height);
    transition: border-radius 250ms ease-in-out;
    cursor: pointer;
    min-width: 18.0rem;
    -webkit-tap-highlight-color: transparent;

    &.opened {
      border-radius: calc(var(--dropdown-height) / 2)  calc(var(--dropdown-height) / 2) 0 0;
    }
  }

  .labelText {
    margin-right: auto;
    color: var(--color-white);
  }

  .triangle {
    --triangle-size: 0.6rem;

    margin-left: var(--base-unit-l);
    width: 0;
    height: 0;
    border-left: var(--triangle-size) solid transparent;
    border-right: var(--triangle-size) solid transparent;
    border-top: var(--triangle-size) solid var(--color-white);
    transition: transform 180ms ease-in-out;

    &.opened {
      transform: rotate(180deg);
    }
  }

  .content {
    --duration: 300ms;

    .has-custom-scroll();

    position: absolute;
    top: calc(var(--dropdown-height) / 2);
    width: 100%;
    background: var(--color-modal);
    overflow-x: hidden;
    overflow-y: auto;
    transition: max-height var(--duration) ease-in-out;
    border-radius: 0 0 calc(var(--dropdown-height) / 4) calc(var(--dropdown-height) / 4);

    &.closed {
      max-height: 0 !important;
    }

    &.animating {
      overflow: hidden;
    }
  }

  .menuContainer {
    padding-top: calc(var(--dropdown-height) / 2);
  }
}
